.album {
    margin-top: -120px !important;
    .col{
        .card{
            background: none !important;
        }
    }
}

.card-columns {
    //column-count: 4;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    @media (min-width: 576px) {
        column-count: 1;
    }

    @media (min-width: 758px) {
        column-count: 3;
    }

    @media (min-width: 992px) {
        column-count: 4;
    }
    .card {
        display: inline-block !important;
        width: 100% !important;
    }
}