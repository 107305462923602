@import './variables.scss';

.card-text{
    font-family: $source-sans-pro-family;
    .subtitle-primary{
        font-size: 10px;
        font-weight: bold;
        color: $subtitle-color;
    }
    .subtitle-secondary{
        font-size: 14px;
        font-weight: bold;
        color: #000;
    }
    .subtitle-third{
        font-size: 14px;
        font-weight: bold;
        color: $subtitle-color;
    }
    .title{
        font-size: 18px;
        font-weight: 900;
        margin-bottom: -10px;
    }
}
